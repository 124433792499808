import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Schedule } from "../../API";
import { useTopPageContext } from "../../provider/TopPageContextProvider";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import LoadingCard from "../parts/card/LoadingCard";
import { ScheduleList } from "../ScheduleList";
import React from "react";
import { useSearchParams } from "react-router-dom";

const TopPageScheduleList = React.memo(() => {
  const { store } = useApplicationStore();      
  const { topPageState } = useTopPageContext();
  const [filteredSchedules, setFilteredSchedules] = useState<Schedule[] | null>(
    null
  );

  useEffect(() => {
    if(store.schedules !== null){
      if (topPageState.selectedDate === null) {
        const now = new Date();
        setFilteredSchedules(
          store.schedules.filter(
            (s) =>
              s.start.split("T")[0] ===
              new Date(now.getTime() + Math.abs(now.getTimezoneOffset() * 60000))
                .toISOString()
                .split("T")[0]
          )
        );
      } else{
        setFilteredSchedules(
          store.schedules!.filter(
            (s) =>
              s.start.split("T")[0] ===
              new Date(
                topPageState.selectedDate!.getTime() +
                  Math.abs(topPageState.selectedDate!.getTimezoneOffset() * 60000)
              )
                .toISOString()
                .split("T")[0]
          )
        );
      } 
    }
  }, [store.schedules, topPageState.selectedDate]);

  return filteredSchedules !== null ? (
    <ScheduleList schedules={filteredSchedules!} />
  ) : (
    <Stack spacing={1}>
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
    </Stack>
  );
});

export default TopPageScheduleList;
