import {
  Customer,
  GetAccountQuery,
  GetCustomerQuery,
} from "../../API";
import { API } from "aws-amplify";
import { getAccount } from "../../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { _getCustomer } from "../queries";

export const CustomerRepository = (shopId: string, lineIdToken: string) => {
  const get = () => {
    return new Promise<Customer | void>(async (res, rej) => {
      const result = (await API.graphql({
        query: getAccount,
        variables: { shopId: shopId },
        authMode: "AWS_LAMBDA",
        authToken: lineIdToken,
      })) as GraphQLResult<GetAccountQuery>;
      if (result.errors) {
        rej(new Error("empty"));
      } else {
        if (result.data?.getAccount !== null) {
          res({
            ...result.data?.getAccount,
            __typename: "Customer",
          } as Customer);
        } else {
          res();
        }
      }
    });
  };
  const fetch = (id: string) => {
    return new Promise<Customer>(async (res, rej) => {
      const result = (await API.graphql({
        query: _getCustomer,
        variables: { id: id, shopId: shopId },
        authMode: "AWS_LAMBDA",
        authToken: lineIdToken,
      })) as GraphQLResult<GetCustomerQuery>;
      if(result.errors){
        rej(result.errors)
      }else{
        res(result.data?.getCustomer!)
      }
    });
  };
  return { get, fetch };
};
