import { formatDate } from "@fullcalendar/react";
import {
  Stack,
  Skeleton,
  Typography,
  styled,
  Box,
  Avatar,
  Paper,
  Divider,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Schedule } from "../../API";
import { getImageFromS3 } from "../../utils/storage";
import { ScheduleDatePanel } from "./ScheduleDatePanel";
import { ScheduleTimePanel } from "./ScheduleTimePanel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

type Props = {
  schedule: Schedule;
};
export const ScheduleInfoPanel: React.FC<Props> = React.memo(
  ({ schedule }) => {
    const [imagePath, setImagePath] = useState<string>("");
    useEffect(() => {
      getImageFromS3(schedule.event?.thumbnail!).then((result) =>
        setImagePath(result)
      );
    }, [schedule]);

    const reservationStatus = useMemo(() => {
      const capacity = schedule.capacity ?? 0
      const acceptableNumber = schedule.acceptableNumber ?? 0
      const ratio = acceptableNumber / capacity;
      if (ratio > 0) {
        return (
          <Stack direction="row" alignItems="center" spacing={0.3}>
            <CheckCircleOutlineIcon fontSize="small" color="success" />
            <Typography variant="body2">予約可</Typography>
          </Stack>
        );
      } else {
        return (
          <Stack direction="row" alignItems="center" spacing={0.3}>
            <ErrorOutlineIcon fontSize="small" color="error" />
            <Typography variant="body2">満員</Typography>
          </Stack>
        );
      }
    }, [schedule]);
    return (
      <Paper variant="outlined">
        <Stack spacing={2.5} p={1.5}>
          <Stack spacing={2.5}>
            <Stack spacing={1.5} px={0.3}>
              <Stack
                direction="row"
                spacing={1.5}
                alignItems="center"
                justifyContent="stretch"
              >
                {imagePath !== "" ? (
                  <Avatar alt="EventImage" src={imagePath} />
                ) : (
                  <Skeleton variant="circular" />
                )}

                <Stack spacing={0.7}>
                  <Typography variant="h3" fontWeight={600}>
                    {schedule.event?.name}
                  </Typography>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>{reservationStatus}</Box>
                  </Stack>
                </Stack>
              </Stack>

              <Stack spacing={1}>
                <ScheduleDatePanel start={schedule.start} />
                <ScheduleTimePanel
                  startTime={schedule.start}
                  endTime={schedule.end}
                />
              </Stack>
            </Stack>
          </Stack>

          <Divider variant="middle" />
          <Box>
            <Typography variant="body1">
              {schedule.event?.description}
            </Typography>
          </Box>
        </Stack>
      </Paper>
    );
  }
);
