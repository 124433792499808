import { formatDate } from "@fullcalendar/react";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
type Props = {
  start: string;
};
export const ScheduleDatePanel: React.FC<Props> = React.memo(
  ({ start }) => {
    return (
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">実施日</Typography>
          <Typography variant="h4">
            {formatDate(start, {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              locale: "ja",
            })}
          </Typography>
        </Stack>
      </Box>
    );
  }
);
