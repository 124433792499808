import { CreateCustomerInput, ReservationStatus } from "../../API"
import * as yup from "yup";

export interface ICreatePreReservation{
  shopId: string
  scheduleId: string
  status: ReservationStatus
  customer: Omit<CreateCustomerInput, "lineId">
}

export const createPreReservationFormSchema = () =>{
  const schema:yup.SchemaOf<ICreatePreReservation> = yup.object({
    shopId: yup.string().required(),
    scheduleId: yup.string().required(),
    status: yup.mixed<ReservationStatus>().oneOf(Object.values(ReservationStatus)).required(),
    customer: yup.object().shape({
      shopId: yup.string().required(),
      id: yup.string(),
      name: yup.string().required(),
      email: yup.string().email().required()
    })
  })
  return schema
}