import { Box } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { CustomerRepository } from "../DAL/repositories/CustomerRepository";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";

export const LiffReservationMyPage = () => {
  const { store, dispatch } = useApplicationStore();
  const { shopId } = useParams();
  useEffect(() => {
    if (store.customer === null) {
      CustomerRepository(shopId!, store.idToken?.token!)
        .get()
        .then((result) => {
          if(result){
            dispatch({
              type: ResourceOperationEvent.FETCH_CUSTOMER,
              payload: result,
            });
          }
        })
        .catch((err) => {
          console.log(err)
        });
    }
  }, [dispatch, shopId, store.customer, store.idToken?.token]);
  return (
    <Box>
      <Outlet />
    </Box>
  );
};
