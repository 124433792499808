import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ScheduleDetail } from "../components/ScheduleDetail";
import { Schedule } from "../API";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";

export const SchedulePage: React.FC = ({ children }) => {

  const {store, dispatch} = useApplicationStore();
  const { shopId, scheduleId } = useParams();
  const [schedule, setSchedule] = useState<Schedule>();

  useEffect(() => {
    if (store.schedules === null) {
      dispatch({
        type: "APP_DATA_FETCHING",
        payload: { shopId: shopId!, idToken: store.idToken?.token! },
      });
    } else {
      setSchedule(
        store.schedules.find((s) => s.shopId === shopId && s.id === scheduleId)
      );
    }
  }, [store.schedules]);
  return  schedule ? <ScheduleDetail schedule={schedule} /> : null;
};
