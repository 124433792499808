import { formatDate, formatRange } from "@fullcalendar/react";
import { Box, Typography, Divider, Card, Stack } from "@mui/material";
import React, { ReactElement } from "react";
import { Schedule } from "../../API";
type Props = {
  schedule: Schedule;
};
export const ReserveSchedulePanel: React.FC<Props> = React.memo(
  ({ schedule, children }) => {
    return (
      <Box>
        <Box>
          <Typography fontWeight={600}>予約内容</Typography>
          <Divider sx={{ margin: "6px 0 12px" }} />
        </Box>
        <Card variant="outlined" elevation={0}>
          <Stack spacing={1} p={1.5}>
            <Stack spacing={0.5}>
              <Typography variant="h4" fontWeight="bold">
                {schedule.event?.name}
              </Typography>
              <Divider />
            </Stack>
            <Stack spacing={1} py={0.3}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5">実施日</Typography>
                <Typography variant="h5">
                  {formatDate(schedule.start, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    separator: "/",
                    locale: "ja",
                  })}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5">開始時刻</Typography>
                <Typography variant="h5">
                  {formatDate(schedule.start, {
                    hour: "2-digit",
                    minute: "2-digit",
                    separator: "-",
                    locale: "ja",
                  })}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5">終了時刻</Typography>
                <Typography variant="h5">
                  {formatDate(schedule.end, {
                    hour: "2-digit",
                    minute: "2-digit",
                    separator: "-",
                    locale: "ja",
                  })}
                </Typography>
              </Stack>
              {children}
            </Stack>
          </Stack>
        </Card>
      </Box>
    );
  }
);
