import { ThemeProvider } from "@emotion/react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ModalProvider from "mui-modal-provider";
import PersonIcon from "@mui/icons-material/Person";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import LiffReservationDrawer from "./LiffReservationDrawer";
import { LiffReservationTheme } from "./LiffReservationTheme";
import { Grid, Paper, Typography } from "@mui/material";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import {
  useApplicationPreference,
} from "../provider/ApplicationPreferenceProvider";

export const LiffReservationClient = () => {
  const navigate = useNavigate();
  const { shopId, customerId } = useParams();
  const { store } = useApplicationStore();
  const { preference } = useApplicationPreference();

  return (
    <ThemeProvider theme={LiffReservationTheme(preference.mode)}>
      <Paper sx={{ borderRadius: 0 }}>
        <ModalProvider>
          <Box sx={{ display: "block", minHeight: "100vh" }}>
            <Box
              pt={3}
              pb="12.5vh"
            >
              <Outlet />
            </Box>
            <AppBar
              position="fixed"
              color="primary"
              sx={{ top: "auto", bottom: 0 }}
            >
              <Toolbar>
                {store.customer !== null ? (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item container xs={4} justifyContent="flex-start">
                      <Grid item>
                        {store.customer !== null ? (
                          <LiffReservationDrawer
                            shopId={shopId!}
                            id={store.customer.id!}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      {customerId ? (
                        <Typography textAlign="center">マイページ</Typography>
                      ) : null}
                    </Grid>
                    <Grid item container xs={4} justifyContent="flex-end">
                      <Grid item>
                        {customerId ? (
                          <IconButton
                            color="inherit"
                            onClick={(e) => {
                              navigate(`/s/${shopId}/`);
                            }}
                          >
                            <CalendarViewMonthIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            color="inherit"
                            onClick={(e) => {
                              navigate(`/s/${shopId}/c/${store.customer?.id}`);
                            }}
                          >
                            <PersonIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Toolbar>
            </AppBar>
          </Box>
        </ModalProvider>
      </Paper>
    </ThemeProvider>
  );
};
