import {
  Box,
  Container,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import MyPageTitlePanel from "../../components/layout/MyPageTitlePanel";
import { ScheduleDatePanel } from "../../components/layout/ScheduleDatePanel";
import { ScheduleTimePanel } from "../../components/layout/ScheduleTimePanel";
import { ScheduleCard } from "../../components/parts/card/ScheduleCard";
import {  Reservation } from "../../API";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { useParams } from "react-router-dom";

export const CustomerReservationsPage = () => {
  const {customerId, shopId} = useParams();
  const { store, dispatch } = useApplicationStore();
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [reservations, setRervations] = useState<(Reservation | null)[]>();
  const handleSelect = useCallback(
    (event: React.MouseEvent<HTMLElement>, newValue: boolean | null) => {
      if (newValue !== null) {
        setIsExpired(newValue);
        setRervations(
          store.customer?.reservations?.items.filter(
            (r) => r?.isExpired === newValue
          )
        );
      }
    },
    [store.customer?.reservations?.items]
  );

  useEffect(() => {
    if (store.customer === null || store.customer.reservations === undefined) {
      dispatch({
        type: "CUSTOMER_FETCHING",
        payload:{
          shopId: shopId!,
          id: customerId!,
          idToken: store.idToken?.token!
        }
      })
    }else{
      setRervations(
        store.customer?.reservations?.items.filter(
          (r) => r?.isExpired === isExpired
        )
      );
    }
  }, [customerId, dispatch, shopId, store.customer, store.idToken?.token]);


  return (
    <Container>
      <Box>
        <Stack spacing={2}>
          <MyPageTitlePanel
            title={`予約一覧`}
            spacing={1.2}
            textAlign="center"
          />
          <Stack alignItems="center">
            <ToggleButtonGroup
              color="primary"
              value={isExpired}
              onChange={handleSelect}
              exclusive
            >
              <ToggleButton value={false}>本日以降</ToggleButton>
              <ToggleButton value={true}>過去の予約</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          {reservations?.length! > 0 ? (
            <Stack spacing={2} p={1}>
              {reservations
                ?.sort((a, b) =>
                  a!.schedule!.start < b!.schedule!.start ? 1 : -1
                )
                .map((reservation) => (
                  <ScheduleCard
                    key={reservation?.id}
                    schedule={reservation?.schedule!}
                  >
                    <ScheduleDatePanel start={reservation?.schedule?.start!} />
                    <ScheduleTimePanel
                      startTime={reservation?.schedule?.start!}
                      endTime={reservation?.schedule?.end!}
                    />
                  </ScheduleCard>
                ))}
            </Stack>
          ) : (
            <Typography padding={1.5} align="center">
              予約はありません。
            </Typography>
          )}
        </Stack>
      </Box>
    </Container>
  );
};
