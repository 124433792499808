import liff from "@line/liff";
import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import { ReservationInfoPanel } from "../components/layout/ReservationInfoPanel";
import { Schedule } from "../API";

export const ReservePage: React.FC = ({ children }) => {
  const { store, dispatch } = useApplicationStore();
  const { shopId, scheduleId } = useParams();
  const [schedule, setSchedule] = useState<Schedule>();

  useEffect(() => {
    if (store.schedules === null) {
      dispatch({
        type: "APP_DATA_FETCHING",
        payload: { shopId: shopId!, idToken: store.idToken?.token!},
      });
    } else {
      initializeState();
    }
  }, [store.schedules]);

  const initializeState = () => {
    const _schedule = store.schedules?.find(
      (s) => s.shopId === shopId && s.id === scheduleId
    );
    setSchedule(_schedule);
  };

  return schedule ? (
    <Container>
      <ReservationInfoPanel schedule={schedule} customer={store.customer} />
    </Container>
  ) : null;
};
