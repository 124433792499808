import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTopPageContext } from "../../provider/TopPageContextProvider";

const TopPageSelectedDateLable = React.memo(() => {
  const { topPageState } = useTopPageContext();

  return (
    <Stack alignItems="center" justifyContent="center">
      <Typography>
        {topPageState.selectedDate?.toLocaleDateString()}のイベント一覧
      </Typography>
    </Stack>
  );
});

export default TopPageSelectedDateLable;
