import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import {Schedule } from "../../API";
import { ScheduleEventCapacityPanel} from "../layout/ScheduleEventCapacityPanel";
import { ScheduleTimePanel } from "../layout/ScheduleTimePanel";
import { TextCard } from "../parts/card";
import { ScheduleCard } from "../parts/card/ScheduleCard";

type Prop = {
  schedules: Schedule[];
};

export const ScheduleList = React.memo<Prop>(({
  schedules,
}) => {
  const isReservable = useCallback((schedule: Schedule)=>{
    const start = new Date(schedule.start).getTime()
    const limit = new Date(start - schedule.event?.reserveTimeoutLimit!).getTime()
    return limit - Date.now() > 0 && (schedule.acceptableNumber?? 0) > 0
  },[])
  return (
    <Stack direction="column" spacing={1}>
      {schedules.length > 0 ? (
        schedules.map((schedule) => (
          <Button
            disabled={!isReservable(schedule)}
            component={Link}
            to={`/s/${schedule.shopId}/sc/${schedule.id}`}
            key={schedule.id}
            sx={{ padding: 0, "&.Mui-disabled > div":{
              backgroundColor: "rgba(0, 0, 0, 0.12)"
            } }}
          >
            <ScheduleCard schedule={schedule} >
              <ScheduleTimePanel
                startTime={schedule.start}
                endTime={schedule.end}
              />
              <ScheduleEventCapacityPanel
                schedule={schedule}
                disabled={!isReservable(schedule)}
              />
            </ScheduleCard>
          </Button>
        ))
      ) : (
        <TextCard
          sx={{ width: "100%" }}
          variant="outlined"
          txt="開催予定のイベントはありません。"
        />
      )}
    </Stack>
  );
});

