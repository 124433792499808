import { Box, Divider, Stack, StackProps, Typography, TypographyProps  } from "@mui/material";
import React from "react";
type TitlePanelProps = StackProps & TypographyProps & {
  title: string;
  
};
const MyPageTitlePanel = React.memo<TitlePanelProps>(({ title, spacing, textAlign}) => {
  return (
    <Stack spacing={spacing}>
      <Typography textAlign={textAlign} variant="h4" fontWeight={600}>{title}</Typography>
      <Divider/>
    </Stack>
  );
});

export default MyPageTitlePanel;
