import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { CreatePreReservationInput, Customer, CustomerInput, ReservationStatus, Schedule } from "../../API";
import {
  createPreReservationFormSchema,
  ICreatePreReservation,
} from "../../DAL/formSchema/CreatePreReservationSchema";
import { ReserveSchedulePanel } from "./ReserveSchedulePanel";
import { customAlphabet } from "nanoid";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";
import { useNavigate } from "react-router-dom";
const alphabet =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const nanoid = customAlphabet(alphabet, 21);

type Props = {
  schedule: Schedule;
  customer: Customer | null;
};

export const ReservationInfoPanel = React.memo<Props>(
  ({ schedule, customer }) => {
    const {
      handleSubmit,
      setValue,
      register,
      formState: { errors },
    } = useForm<ICreatePreReservation>({
      resolver: yupResolver(createPreReservationFormSchema()),
      defaultValues: {
        shopId: schedule.shopId,
        scheduleId: schedule.id,
        status: ReservationStatus.Entry,
        customer: {
          shopId: customer?.shopId ?? schedule.shopId,
          id: customer?.id ?? "",
          name: customer?.name ?? "",
          email: customer?.email ?? "",
        },
      },
    });
    const {store, dispatch} = useApplicationStore();
    const navigate = useNavigate();
    const redirectPath = `/s/${schedule.shopId}/sc/${schedule.id}/reserve/transaction`;

    const onSubmit = useCallback((data: ICreatePreReservation)=>{
      const preReservationInput:CreatePreReservationInput = {
        shopId: data.shopId,
        scheduleId:data.scheduleId,
        status: data.status
      }
      const customerInput:CustomerInput = {
        shopId: data.customer.shopId,
        id: data.customer.id,
        name: data.customer.name!,
        email: data.customer.email!,
        exists: customer !== null
      }
      dispatch({
        type: "START_PRE_RESERVATION",
        payload:{
          preReservationInput: preReservationInput,
          customerInput: customerInput,
          idToken: store.idToken?.token!
        }
      })
      navigate(redirectPath);
    },[customer, dispatch, navigate, redirectPath, store.idToken?.token])
    return (
      <Stack>
        <Paper
          variant="outlined"
          sx={{ margin: "12px 0 32px", padding: "12px" }}
        >
          <Stack spacing={3} px={1} py={1.5}>
            <ReserveSchedulePanel schedule={schedule} />
            <Box>
              <Typography fontWeight={600}>お客様情報</Typography>
              <Divider sx={{ margin: "6px 0 12px" }} />
              <Stack spacing={1}>
                <TextField
                  margin="dense"
                  size="small"
                  id="name"
                  label="氏名"
                  fullWidth
                  variant="filled"
                  {...register("customer.name")}
                  error={"customer.name" in errors}
                  helperText={<Typography variant="error">{errors.customer?.name?.message}</Typography>}
                  placeholder="氏名"
                />
                <TextField
                  margin="dense"
                  size="small"
                  id="email"
                  label="メールアドレス"
                  fullWidth
                  variant="filled"
                  {...register("customer.email")}
                  error={"customer.email" in errors}
                  helperText={<Typography variant="error">{errors.customer?.email?.message}</Typography>}
                  placeholder="name@example.com"
                />
              </Stack>
            </Box>
          </Stack>
        </Paper>
        <Button
          variant="contained"
          disableElevation
          onClick={handleSubmit(onSubmit)}
        >
          登録する
        </Button>
      </Stack>
    );
  }
);
