import { createContext, Reducer, useContext, useMemo } from "react";
import Stripe from "stripe";
import { useReducerAsync, AsyncActionHandlers } from "use-reducer-async";
import {
  Shop,
  Schedule,
  Reservation,
  Event,
  CreatePreReservationInput,
  Customer,
  CustomerInput,
} from "../API";
import { CustomerRepository } from "../DAL/repositories/CustomerRepository";
import { ReservationRepository } from "../DAL/repositories/ReservationRepository";
import { ScheduleRepository } from "../DAL/repositories/ScheduleRepository";
import { ShopRepository } from "../DAL/repositories/ShopRepository";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";

type IdToken = {
  token: string;
  expiredAt?: string;
};
type Transaction = {
  status: TransactionStatus;
  message: string | null | undefined;
};
export enum TransactionStatus {
  entry,
  processing,
  succeed,
  error,
}
export type PaymentAppTransaction = Transaction & {
  paymentIntentId: string | null | undefined;
};
export type ReservationAppTransaction = Transaction;
export type StripeCustomerObject = {
  stripeAccount: Stripe.Customer;
  stripePaymentMethods: Stripe.PaymentMethod[] | null;
};

export type STORE = {
  shop: Shop | null;
  customer: Customer | null;
  schedules: Schedule[] | null;
  reservations: Reservation[] | null;
  events: Event[] | null;
  idToken: IdToken | null;
  storeId: string | null;
  reservationTransaction: ReservationAppTransaction | null;
};

const initialStore: STORE = {
  shop: null,
  customer: null,
  schedules: null,
  reservations: null,
  events: null,
  idToken: null,
  storeId: null,
  reservationTransaction: null,
};

export type ASYNCACTION =
  | {
      type: "APP_DATA_FETCHING";
      payload: {
        shopId: string;
        idToken: string;
      };
    }
  | {
      type: "CUSTOMER_FETCHING";
      payload: {
        shopId: string;
        id: string;
        idToken: string;
      };
    }
  | {
      type: "START_PRE_RESERVATION";
      payload: {
        preReservationInput: CreatePreReservationInput;
        customerInput: CustomerInput;
        idToken: string;
      };
    };

export type ACTIONTYPE =
  | { type: ResourceOperationEvent.FETCH_SHOP; payload: Shop | null }
  | { type: ResourceOperationEvent.FETCH_CUSTOMER; payload: Customer }
  | { type: ResourceOperationEvent.FETCH_EVENT; payload: Event[] }
  | { type: ResourceOperationEvent.FETCH_SCHEDULE; payload: Schedule[] }
  | { type: ResourceOperationEvent.GET_SHOP; payload: Shop }
  | { type: ResourceOperationEvent.GET_CUSTOMER; payload: Customer }
  | { type: ResourceOperationEvent.GET_EVENT; payload: Event }
  | { type: ResourceOperationEvent.GET_SCHEDULE; payload: Schedule }
  | { type: ResourceOperationEvent.GET_RESERVATION; payload: Reservation }
  | { type: ResourceOperationEvent.CREATE_CUSTOMER; payload: Customer }
  | { type: ResourceOperationEvent.CREATE_EVENT; payload: Event }
  | { type: ResourceOperationEvent.CREATE_SCHEDULE; payload: Schedule }
  | { type: ResourceOperationEvent.CREATE_RESERVATION; payload: Reservation }
  | { type: ResourceOperationEvent.UPDATE_SHOP; payload: Shop }
  | { type: ResourceOperationEvent.UPDATE_CUSTOMER; payload: Customer }
  | {
      type: ResourceOperationEvent.UPDATE_STRIPE_CUSTOMER;
      payload: StripeCustomerObject;
    }
  | { type: ResourceOperationEvent.UPDATE_EVENT; payload: Event }
  | { type: ResourceOperationEvent.UPDATE_SCHEDULE; payload: Schedule }
  | { type: ResourceOperationEvent.UPDATE_RESERVATION; payload: Reservation }
  | { type: ResourceOperationEvent.DELETE_CUSTOMER; payload: Customer }
  | { type: ResourceOperationEvent.DELETE_EVENT; payload: Event }
  | { type: ResourceOperationEvent.DELETE_SCHEDULE; payload: Schedule }
  | { type: ResourceOperationEvent.DELETE_RESERVATION; payload: Reservation }
  | { type: ResourceOperationEvent.SET_ID_TOKEN; payload: string }
  | { type: ResourceOperationEvent.FETCH_LIFF; payload: string }
  | {
      type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION;
      payload: ReservationAppTransaction;
    };

const ApplicationStoreContext = createContext(
  {} as {
    store: STORE;
    dispatch: React.Dispatch<ACTIONTYPE | ASYNCACTION>;
  }
);

const reducer = (store: STORE, action: ACTIONTYPE) => {
  switch (action.type) {
    case ResourceOperationEvent.GET_SHOP:
      return { ...store, shop: action.payload };
    case ResourceOperationEvent.FETCH_CUSTOMER:
      return {
        ...store,
        customer: action.payload,
        reservations:
          action.payload.reservations?.items.length! > 0
            ? (action.payload.reservations?.items as Reservation[])
            : null,
      };
    case ResourceOperationEvent.UPDATE_CUSTOMER:
      return {
        ...store,
        customer: action.payload,
      };
    case ResourceOperationEvent.CREATE_RESERVATION:
      return {
        ...store,
        reservations: [...store.reservations!, action.payload],
      };
    case ResourceOperationEvent.FETCH_EVENT:
      return { ...store, events: action.payload };
    case ResourceOperationEvent.FETCH_SCHEDULE:
      return { ...store, schedules: action.payload };
    case ResourceOperationEvent.SET_ID_TOKEN:
      return { ...store, idToken: { token: action.payload, expiredAt: "" } };
    case ResourceOperationEvent.SET_RESERVATION_TRANSACTION:
      return { ...store, reservationTransaction: action.payload };
    default:
      return store;
  }
};

const asyncActionHandlers: AsyncActionHandlers<
  Reducer<STORE, ACTIONTYPE>,
  ASYNCACTION
> = {
  APP_DATA_FETCHING:
    ({ dispatch }) =>
    async (action) => {
      const scheduleClient = ScheduleRepository(action.payload.shopId, action.payload.idToken);
      const shopClient = ShopRepository(action.payload.shopId, action.payload.idToken);
      const customerClient = CustomerRepository(
        action.payload.shopId,
        action.payload.idToken
      );
      await Promise.all([
        shopClient.get(),
        scheduleClient.fetch(),
        customerClient.get(),
      ]).then((results) => {
        dispatch({
          type: ResourceOperationEvent.GET_SHOP,
          payload: results[0],
        });
        dispatch({
          type: ResourceOperationEvent.FETCH_SCHEDULE,
          payload: results[1],
        });
        if (results[2] && results[2]?.id !== null) {
          dispatch({
            type: ResourceOperationEvent.FETCH_CUSTOMER,
            payload: results[2],
          });
        }
      });
    },
  START_PRE_RESERVATION:
    ({ dispatch }) =>
    async (action) => {
      dispatch({
        type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
        payload: {
          status: TransactionStatus.entry,
          message: "しばらくこのままでお待ちください。",
        },
      });
      const reservationClient = ReservationRepository(
        action.payload.preReservationInput.shopId,
        action.payload.idToken
      );
      const customerClient = CustomerRepository(
        action.payload.preReservationInput.shopId,
        action.payload.idToken
      );
      await reservationClient
        .create(
          action.payload.preReservationInput,
          action.payload.customerInput
        )
        .then((result) => {
          dispatch({
            type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
            payload: {
              status: TransactionStatus.processing,
              message: "予約の確定処理中です。",
            },
          });
          return customerClient.fetch(result.customer?.id!);
        })
        .then((result) => {
          dispatch({
            type: ResourceOperationEvent.UPDATE_CUSTOMER,
            payload: result,
          });
        })
        .catch((err) => {
          dispatch({
            type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
            payload: {
              status: TransactionStatus.error,
              message: "予約が失敗しました。",
            },
          });
        });
    },
  CUSTOMER_FETCHING:
    ({ dispatch }) =>
    async (action) => {
      const customerClient = CustomerRepository(
        action.payload.shopId,
        action.payload.idToken
      );
      const customer = await customerClient.fetch(action.payload.id)
      dispatch({
        type: ResourceOperationEvent.UPDATE_CUSTOMER,
        payload: customer,
      });
    },
};

type Props = {
  children?: React.ReactNode;
};

export const ApplicationStoreProvider: React.FC<Props> = (props) => {
  const [store, dispatch] = useReducerAsync<
    Reducer<STORE, ACTIONTYPE>,
    ASYNCACTION,
    ACTIONTYPE | ASYNCACTION
  >(reducer, initialStore, asyncActionHandlers);
  const value = useMemo(() => ({ store, dispatch }), [dispatch, store]);

  return <ApplicationStoreContext.Provider value={value} {...props} />;
};

export const useApplicationStore = () => {
  const context = useContext(ApplicationStoreContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useApplicationStore must be within a ApplicationStoreProvider."
    );
  }
  return context;
};
