import { API } from "aws-amplify";
import { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import {
  UpdateReservationInput,
  DeleteReservationInput,
  CreatePreReservationInput,
  CreatePreReservationMutation,
  CustomerInput,
  CreatePreReservationResult,
} from "../../API";
import {
  createPreReservation,
  deleteReservation,
  updateReservation,
} from "../../graphql/mutations";

export const ReservationRepository = (shopId: string, lineIdToken: string) => {
  const create = (
    preReservationInput: CreatePreReservationInput,
    customerInput: CustomerInput
  ) => {
    return new Promise<CreatePreReservationResult>(async (res, rej) => {
      const result = (await API.graphql({
        query: createPreReservation,
        variables: {
          preReservationInput: preReservationInput,
          customerInput: customerInput,
        },
        authMode: "AWS_LAMBDA",
        authToken: lineIdToken,
      })) as GraphQLResult<CreatePreReservationMutation>;
      if (result.errors) {
        rej(result.errors);
      } else {
        res(result.data?.createPreReservation!);
      }
    });
  };

  const update = (input: UpdateReservationInput) => {
    return new Promise<void>(async (res, rej) => {
      try {
        await API.graphql(
          graphqlOperation(updateReservation, { input: input })
        );
        res();
      } catch (err) {
        rej(new Error("update error"));
      }
    });
  };

  const remove = (input: DeleteReservationInput) => {
    return new Promise<void>(async (res, rej) => {
      try {
        (await API.graphql(
          graphqlOperation(deleteReservation, { input: input })
        )) as GraphQLResult<DeleteReservationInput>;
        res();
      } catch (err) {
        rej(err);
      }
    });
  };

  return {
    create,
    update,
    remove,
  };
};
