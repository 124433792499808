import { Card, CardProps, Stack, Typography } from "@mui/material";
import React from "react";
import { TextCardProps } from "./types";

const TextCard = React.memo<TextCardProps>((props) => {
  const cardProps = (({ txt, ..._props }) => _props)(props);
  return (
    <Card {...cardProps}>
      <Stack alignItems="center" justifyContent="center" p={2}>
        <Typography>{props.txt}</Typography>
      </Stack>
    </Card>
  );
});
export default TextCard;
