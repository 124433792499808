import { createContext, useContext, useMemo, useReducer } from "react";

export enum TopPageOperation {
  SELECT_DATE,
  IDLE,
  SORT_SCHEDULE
}

export type TopPageState = {
  selectedDate: Date | null;
};

export type TopPageAction =
  | {
      type: TopPageOperation.SELECT_DATE;
      payload: Date;
    }

export const TopPageContext = createContext(
  {} as {
    topPageState: TopPageState;
    topPageDispatch: React.Dispatch<TopPageAction>;
  }
);

type Props = {
  children?: React.ReactNode;
};

const reducer = (state: TopPageState, action: TopPageAction) => {
  switch (action.type) {
    case TopPageOperation.SELECT_DATE:
      return { ...state, selectedDate: new Date(action.payload.getTime()) };
    default:
      return state;
  }
};

export const TopPageContextProvider: React.FunctionComponent<Props> = (
  props
) => {
  const [topPageState, topPageDispatch] = useReducer(reducer, {
    selectedDate: new Date()
  });
  const value = useMemo(
    () => ({ topPageState, topPageDispatch }),
    [topPageState]
  );
  return <TopPageContext.Provider value={value} {...props} />;
};

export const useTopPageContext = () => {
  const context = useContext(TopPageContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useTopPageContext must be within a TopPageContextProvider"
    );
  }
  return context;
};

export default TopPageContextProvider;
