import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams} from "react-router-dom";
import MyPageTitlePanel from "../../components/layout/MyPageTitlePanel";
import { ScheduleDatePanel } from "../../components/layout/ScheduleDatePanel";
import { ScheduleTimePanel } from "../../components/layout/ScheduleTimePanel";
import { TextCard } from "../../components/parts/card";
import { ScheduleCard } from "../../components/parts/card/ScheduleCard";
import { useApplicationStore } from "../../provider/ApplicationStoreProvider";

export const CustomerIndexPage = () => {

  const {customerId, shopId} = useParams();
  const { store, dispatch } = useApplicationStore();
  useEffect(() => {
    if (store.customer === null || store.customer.reservations === undefined) {

      dispatch({
        type: "CUSTOMER_FETCHING",
        payload:{
          shopId: shopId!,
          id: customerId!,
          idToken: store.idToken?.token!
        }
      })
    }
  }, [customerId, dispatch, shopId, store.customer, store.idToken?.token]);
  return (
    <Container>
      <Box>
        {store.customer !== null ? (
          <Stack spacing={2}>
            <MyPageTitlePanel
              title={`${store.customer?.name}さんのマイページ`}
              spacing={1.2}
              textAlign="center"
            />
            <Paper elevation={0} variant="outlined">
              {store.customer?.reservations?.items.length! >
              0 ? (
                <Stack spacing={2} p={2}>
                  <Typography variant="h4" textAlign="center" fontWeight={600}>
                    直近の予約
                  </Typography>
                  {store.customer?.reservations?.items
                    .sort((a, b) =>
                      a!.schedule!.start < b!.schedule!.start ? 1 : -1
                    )
                    .filter((_, idx) => idx < 3)
                    .map((reservation) => (
                      <ScheduleCard
                        key={reservation?.id}
                        schedule={reservation?.schedule!}
                      >
                        <ScheduleDatePanel
                          start={reservation?.schedule?.start!}
                        />
                        <ScheduleTimePanel
                          startTime={reservation?.schedule?.start!}
                          endTime={reservation?.schedule?.end!}
                        />
                      </ScheduleCard>
                    ))}
                  <Button
                    variant="contained"
                    disableElevation
                    component={Link}
                    to={`/s/${store.customer?.shopId}/c/${
                      store.customer?.id
                    }/reservations`}
                  >
                    全ての予約を見る
                  </Button>
                </Stack>
              ) : (
                <Typography padding={1.5} align="center">
                  コンテンツはまだありません
                </Typography>
              )}
            </Paper>
          </Stack>
        ) : (
          <Stack spacing={2} py={2}>
            <TextCard
              sx={{ width: "100%" }}
              variant="outlined"
              txt="ユーザー登録されていません。"
            />
          </Stack>
        )}
      </Box>
    </Container>
  );
};
