export const _getCustomer = /* GraphQL */ `
  query GetCustomer($shopId: ID!, $id: ID!) {
    getCustomer(shopId: $shopId, id: $id) {
      shopId
      id
      name
      lineId
      email
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          schedule {
            shopId
            id
            eventId
            event {
              id
              shopId
              name
              thumbnail
              description
              duration
              capacity
              canPublish
              color
              order
              cancelTimeoutLimit
              reserveTimeoutLimit
              createdAt
              updatedAt
            }
            start
            end
            capacity
            acceptableNumber
            isPublished
            isExpired
            createdAt
            updatedAt
          }
          transactionId
          transaction {
            reservationId
            id
            customerId
            createdAt
            updatedAt
          }
          customerId
          customer {
            shopId
            id
            name
            lineId
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
