import liff from "@line/liff";
import { Button, Paper, Stack } from "@mui/material";
import { Container } from "@mui/system";
import AWSAppSyncClient from "aws-appsync";
import gql from "graphql-tag";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ReserveSchedulePanel } from "../components/layout/ReserveSchedulePanel";
import { TransactionMessagePanel } from "../components/layout/TransactionMessagePanel";
import {
  OnUpdateReservationByCustomerIdSubscription,
  ReservationStatus,
  Schedule,
} from "../API";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";
import { onUpdateReservationByCustomerId } from "../graphql/subscriptions";

import {
  TransactionStatus,
  useApplicationStore,
} from "../provider/ApplicationStoreProvider";
import { API } from "aws-amplify";

export const ReservationTransactionPage = () => {
  const { shopId, scheduleId } = useParams();
  const { store, dispatch } = useApplicationStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [schedule, setSchedule] = useState<Schedule>();

  const initializeState = useCallback(() => {
    setSchedule(
      store.customer?.reservations?.items
        ?.map((r) => r?.schedule!)
        ?.find((s) => s?.shopId === shopId && s?.id === scheduleId)
    );
  }, [scheduleId, shopId, store.customer]);

  useEffect(() => {
    initializeState();
    const updateSub = API.graphql({
      query: onUpdateReservationByCustomerId,
      variables: { customerId: store.customer?.id },
      authMode: "AWS_LAMBDA",
      authToken: store.idToken?.token!,
      //@ts-ignore
    }).subscribe({
      next: async ({ provider, value }: any) => {

        const data = value.data as OnUpdateReservationByCustomerIdSubscription;
        switch (data.onUpdateReservationByCustomerId?.status) {
          case ReservationStatus.Faild:
            dispatch({
              type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
              payload: {
                status: TransactionStatus.error,
                message: "予約が完了しませんでした。",
              },
            });
            setSearchParams({
              style: searchParams.get("style")!,
              status: "error",
            });
            break;
          case ReservationStatus.Success:
            //setSchedule(data.onUpdateReservationByCustomerId.schedule!);
            dispatch({
              type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
              payload: {
                status: TransactionStatus.succeed,
                message: "予約が確定しました。",
              },
            });

            setSearchParams({
              status: "succeed",
            });
            break;
          default:
            dispatch({
              type: ResourceOperationEvent.SET_RESERVATION_TRANSACTION,
              payload: {
                status: TransactionStatus.processing,
                message: "処理中です",
              },
            });
            setSearchParams({
              style: searchParams.get("style")!,
              status: "processing",
            });
            break;
        }
      },
    });

    return () => {
      updateSub.unsubscribe();
    };
  }, [dispatch, initializeState, searchParams, setSearchParams, store.customer, store.idToken?.token]);
  return (
    <Container>
      <Stack spacing={2}>
        {store.reservationTransaction ? (
          <Paper variant="outlined">
            <Stack spacing={2} p={2}>
              <TransactionMessagePanel
                transaction={store.reservationTransaction}
              />
              {store.reservationTransaction?.status ===
                TransactionStatus.succeed && schedule ? (
                <ReserveSchedulePanel schedule={schedule} />
              ) : null}
            </Stack>
          </Paper>
        ) : null}
        <Stack>
          {store.reservationTransaction?.status === TransactionStatus.succeed ||
          store.reservationTransaction?.status === TransactionStatus.error ? (
            <Button
              variant="contained"
              disableElevation
              component={Link}
              to={`/s/${store.shop?.id}/`}
            >
              トップページに戻る
            </Button>
          ) : null}
        </Stack>
      </Stack>
    </Container>
  );
};
