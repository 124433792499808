import { formatDate } from "@fullcalendar/react";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
type Props = {
  startTime: string;
  endTime: string;
}
export const ScheduleTimePanel: React.FC<Props> = React.memo(({startTime, endTime}) => {
  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4">
          開始時刻:
          {formatDate(startTime, {
            hour: "2-digit",
            minute: "2-digit",
            locale: "ja",
          })}
        </Typography>
        <Typography variant="h4">
          終了時刻:
          {formatDate(endTime, {
            hour: "2-digit",
            minute: "2-digit",
            locale: "ja",
          })}
        </Typography>
      </Stack>
    </Box>
  );
});
