import { useEffect } from "react";
import {
  Outlet,
  RouteProps,
} from "react-router-dom";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import liff from "@line/liff";
import { ResourceOperationEvent } from "../DAL/ResourceEvents";
import {
  Backdrop,
  Card,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";

export const ProtectedRoute: React.FC<RouteProps> = () => {

  const { store, dispatch } = useApplicationStore();
  useEffect(() => {
    
    if (store.idToken === null) {
      liff
        .init({ liffId: liff.id! })
        .then(async () => {
          if (liff.isLoggedIn() && liff.isInClient()) {
            dispatch({
              type: ResourceOperationEvent.SET_ID_TOKEN,
              payload: liff.getIDToken()!,
            });
          } else {
            if (process.env.NODE_ENV === "development") {
              dispatch({
                type: ResourceOperationEvent.SET_ID_TOKEN,
                payload: "line-id-token",
              });
            }else{
              throw new Error("not be in line client");
              
            }
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  }, [dispatch, store.idToken]);

  return store.idToken !== null ? (
    <Outlet />
  ) : (
    <Backdrop open={true}>
      <Card elevation={0}>
        <Stack
          spacing={2}
          pt={2}
          px={2}
          pb={1}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
          <Typography variant="body2">初期化中</Typography>
        </Stack>
      </Card>
    </Backdrop>
  );
};
