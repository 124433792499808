import {
  IconButton,
  SwipeableDrawer,
  Box,
  List,
  ListItem,
  Typography,
  ListItemText,
  Divider,
  ListItemButton,
  ListItemIcon,
  Stack,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import styled from "@emotion/styled";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import { useNavigate } from "react-router-dom";
import ThemeModeSwitch from "../components/Switch/ThemeModeSwitch";
import {
  PreferenceEvent,
  useApplicationPreference,
} from "../provider/ApplicationPreferenceProvider";

const MyPageDrawer = styled(SwipeableDrawer)({
  backgroundColor: "rgba(26,20,35,0)",
});

const DrawerListItem = styled(ListItem)({
  padding: 0,
});

const DrawerListItemButton = styled(ListItemButton)({
  padding: "12px 4px",
});

const DrawerListItemIcon = styled(ListItemIcon)({
  justifyContent: "center",
});

const DrawerListItemSection = styled(ListItem)({
  padding: "8px 12px",
});

type DrawerState = {
  isOpen: boolean;
};
type DrawerProps = {
  shopId: string;
  id: string;
};

const LiffReservationDrawer: React.FC<DrawerProps> = (props) => {
  const navigate = useNavigate();
  const [drawerState, setDrawerState] = useState<DrawerState>({
    isOpen: false,
  });
  const toggleDrawer = useCallback(
    (open: boolean) => (e: React.KeyboardEvent | React.MouseEvent) => {
      if (
        e &&
        e.type === "keydown" &&
        ((e as React.KeyboardEvent).key === "Tab" ||
          (e as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerState({ isOpen: open });
    },
    []
  );
  const linkButtonHandler = useCallback(
    (path: string, isMyPage: boolean) =>
      (e: React.KeyboardEvent | React.MouseEvent) => {
        setDrawerState({ isOpen: false });
        isMyPage
          ? navigate(`/s/${props.shopId}/c/${props.id}/${path}`)
          : navigate(`/s/${props.shopId}/${path}`);
      },
    [navigate, props.id, props.shopId]
  );
  const { preferenceDispatcher } = useApplicationPreference();
  const defaultMode = localStorage.getItem("themeMode") ?? "light";
  const [isLightMode, setLightMode] = useState<boolean>(
    defaultMode === "light" ? true : false
  );
  const modeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLightMode(event.target.checked);
    preferenceDispatcher({
      type: PreferenceEvent.SET_MODE,
      payload: event.target.checked ? "light" : "dark",
    });
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <MyPageDrawer
        open={drawerState.isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box width="70vw" py={2}>
          <List>
            <DrawerListItem>
              <DrawerListItemButton
                onClick={linkButtonHandler("", true)}
              >
                <DrawerListItemIcon>
                  <DashboardIcon />
                </DrawerListItemIcon>
                <ListItemText secondary="マイページトップ" />
              </DrawerListItemButton>
            </DrawerListItem>
            <DrawerListItem>
              <DrawerListItemButton
                onClick={linkButtonHandler("reservations", true)}
              >
                <DrawerListItemIcon>
                  <SelfImprovementIcon />
                </DrawerListItemIcon>
                <ListItemText secondary="予約一覧" />
              </DrawerListItemButton>
            </DrawerListItem>
            <Divider />
            <DrawerListItemSection>
              <ListItemText primary="モード切り替え" />
            </DrawerListItemSection>
            <Divider />
            <DrawerListItem>
              <Stack
                direction="row"
                alignItems="center"
                p="12px 8px"
                justifyContent="space-around"
                width="100%"
              >
                <Typography>
                  {isLightMode ? "ライトモード" : "ダークモード"}
                </Typography>
                <ThemeModeSwitch onChange={modeHandler} checked={isLightMode} />
              </Stack>
            </DrawerListItem>
          </List>
        </Box>
      </MyPageDrawer>
    </>
  );
};

export default LiffReservationDrawer;
