import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import React from "react";

const LoadingCard = React.memo(() => {
  return (
    <Card variant="outlined">
      <Grid p={2}>
        <Stack>
          <Skeleton variant="text" width="40%" />
          <Skeleton variant="text" width="70%" />
          <Skeleton variant="text" width="100%" />
        </Stack>
      </Grid>
    </Card>
  );
});

export default LoadingCard;
