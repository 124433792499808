import { GetShopQuery, Shop } from "../../API";
import { API } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { getShop } from "../../graphql/queries";
export const ShopRepository = (shopId: string, lineIdToken: string) => {
  const get = () => {
    return new Promise<Shop>(async (res, rej) => {
      const result = (await API.graphql({
        query: getShop,
        variables: { id: shopId },
        authMode: "AWS_LAMBDA",
        authToken: lineIdToken,
      })) as GraphQLResult<GetShopQuery>;
      if (result.data?.getShop) {
        res(result.data?.getShop);
      } else {
        rej(new Error("empty"));
      }
    });
  };

  return {
    get,
  };
};
