import { Container, Box, Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import React from "react";
import { Link } from "react-router-dom";
import { Schedule } from "../../API";
import { ScheduleInfoPanel } from "../layout/ScheduleInfoPanel";

type Prop = {
  schedule: Schedule;
};

export const ScheduleDetail: React.FC<Prop> = React.memo(({ schedule }) => {
  return (
    <Container maxWidth="sm">
      <Box>
        <Stack spacing={2}>
          <ScheduleInfoPanel schedule={schedule} />

          <Stack mt={2}>
            <Button
              variant="contained"
              disableElevation
              component={Link}
              to={`/s/${schedule.shopId}/sc/${schedule.id}/reserve`}
            >
              予約する
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
});
