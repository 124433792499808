import { Storage } from "aws-amplify";
export const getImageFromS3 = (filename:string)=>{
  return new Promise<string>(async(res, rej)=>{
    if(filename===null) rej()
    await Storage.get(filename).then(result=>res(result)).catch(err=>rej(err))
  })
}
export const removeImageFromS3 = (filename: string) => {
  return new Promise<void>(async (res, rej) => {
    await Storage.remove(filename)
      .then((result) => res())
      .catch((err) => rej());
  });
};
export const uploadImage2S3 = (filename: string | undefined | null, data: File | undefined| null) => {
  return new Promise<void>(async (res, rej) => {
    if(filename !== null){
      await Storage.put(filename!, data, {
        contentType: data?.type,
      })
        .then((result) => res())
        .catch((err) => rej(err));
    }else{
      res()
    }

  });
};
