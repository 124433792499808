export enum ResourceOperationEvent {
  FETCH_SHOP,
  FETCH_EVENT,
  FETCH_SCHEDULE,
  FETCH_RESERVATION,
  FETCH_CUSTOMER,
  GET_SHOP,
  GET_EVENT,
  GET_SCHEDULE,
  GET_RESERVATION,
  GET_CUSTOMER,
  CREATE_EVENT,
  CREATE_SCHEDULE,
  CREATE_RESERVATION,
  CREATE_CUSTOMER,
  UPDATE_SHOP,
  UPDATE_EVENT,
  UPDATE_SCHEDULE,
  UPDATE_RESERVATION,
  UPDATE_CUSTOMER,
  UPDATE_STRIPE_CUSTOMER,
  DELETE_EVENT,
  DELETE_SCHEDULE,
  DELETE_RESERVATION,
  DELETE_CUSTOMER,
  FETCH_LIFF,
  SET_ID_TOKEN,
  SET_RESERVATION_TRANSACTION,
}