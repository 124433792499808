/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onBatchAddSchedules = /* GraphQL */ `
  subscription OnBatchAddSchedules($shopId: ID!) {
    onBatchAddSchedules(shopId: $shopId) {
      shopId
      data {
        shopId
        id
        eventId
        event {
          id
          shopId
          name
          thumbnail
          description
          duration
          capacity
          canPublish
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        capacity
        acceptableNumber
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        isPublished
        isExpired
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateReservationByCustomerId = /* GraphQL */ `
  subscription OnUpdateReservationByCustomerId($customerId: ID!) {
    onUpdateReservationByCustomerId(customerId: $customerId) {
      shopId
      id
      status
      expiredAt
      isExpired
      scheduleId
      schedule {
        shopId
        id
        eventId
        event {
          id
          shopId
          name
          thumbnail
          description
          duration
          capacity
          canPublish
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        capacity
        acceptableNumber
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        isPublished
        isExpired
        createdAt
        updatedAt
      }
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        name
        lineId
        email
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateShop = /* GraphQL */ `
  subscription OnCreateShop($owner: String) {
    onCreateShop(owner: $owner) {
      id
      name
      address
      email
      tel
      dayoff
      holidays
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateShop = /* GraphQL */ `
  subscription OnUpdateShop($owner: String) {
    onUpdateShop(owner: $owner) {
      id
      name
      address
      email
      tel
      dayoff
      holidays
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteShop = /* GraphQL */ `
  subscription OnDeleteShop($owner: String) {
    onDeleteShop(owner: $owner) {
      id
      name
      address
      email
      tel
      dayoff
      holidays
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent($shopId: String) {
    onCreateEvent(shopId: $shopId) {
      id
      shopId
      name
      thumbnail
      description
      duration
      capacity
      canPublish
      color
      order
      cancelTimeoutLimit
      reserveTimeoutLimit
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent($shopId: String) {
    onUpdateEvent(shopId: $shopId) {
      id
      shopId
      name
      thumbnail
      description
      duration
      capacity
      canPublish
      color
      order
      cancelTimeoutLimit
      reserveTimeoutLimit
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent($shopId: String) {
    onDeleteEvent(shopId: $shopId) {
      id
      shopId
      name
      thumbnail
      description
      duration
      capacity
      canPublish
      color
      order
      cancelTimeoutLimit
      reserveTimeoutLimit
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSchedule = /* GraphQL */ `
  subscription OnCreateSchedule($shopId: String) {
    onCreateSchedule(shopId: $shopId) {
      shopId
      id
      eventId
      event {
        id
        shopId
        name
        thumbnail
        description
        duration
        capacity
        canPublish
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      capacity
      acceptableNumber
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          schedule {
            shopId
            id
            eventId
            start
            end
            capacity
            acceptableNumber
            isPublished
            isExpired
            createdAt
            updatedAt
          }
          transactionId
          transaction {
            reservationId
            id
            customerId
            createdAt
            updatedAt
          }
          customerId
          customer {
            shopId
            id
            name
            lineId
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isPublished
      isExpired
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSchedule = /* GraphQL */ `
  subscription OnUpdateSchedule($shopId: String) {
    onUpdateSchedule(shopId: $shopId) {
      shopId
      id
      eventId
      event {
        id
        shopId
        name
        thumbnail
        description
        duration
        capacity
        canPublish
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      capacity
      acceptableNumber
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          schedule {
            shopId
            id
            eventId
            start
            end
            capacity
            acceptableNumber
            isPublished
            isExpired
            createdAt
            updatedAt
          }
          transactionId
          transaction {
            reservationId
            id
            customerId
            createdAt
            updatedAt
          }
          customerId
          customer {
            shopId
            id
            name
            lineId
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isPublished
      isExpired
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSchedule = /* GraphQL */ `
  subscription OnDeleteSchedule($shopId: String) {
    onDeleteSchedule(shopId: $shopId) {
      shopId
      id
      eventId
      event {
        id
        shopId
        name
        thumbnail
        description
        duration
        capacity
        canPublish
        color
        order
        cancelTimeoutLimit
        reserveTimeoutLimit
        createdAt
        updatedAt
      }
      start
      end
      capacity
      acceptableNumber
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          schedule {
            shopId
            id
            eventId
            start
            end
            capacity
            acceptableNumber
            isPublished
            isExpired
            createdAt
            updatedAt
          }
          transactionId
          transaction {
            reservationId
            id
            customerId
            createdAt
            updatedAt
          }
          customerId
          customer {
            shopId
            id
            name
            lineId
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isPublished
      isExpired
      createdAt
      updatedAt
    }
  }
`;
export const onCreateReservation = /* GraphQL */ `
  subscription OnCreateReservation($shopId: String) {
    onCreateReservation(shopId: $shopId) {
      shopId
      id
      status
      expiredAt
      isExpired
      scheduleId
      schedule {
        shopId
        id
        eventId
        event {
          id
          shopId
          name
          thumbnail
          description
          duration
          capacity
          canPublish
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        capacity
        acceptableNumber
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        isPublished
        isExpired
        createdAt
        updatedAt
      }
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        name
        lineId
        email
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateReservation = /* GraphQL */ `
  subscription OnUpdateReservation($shopId: String) {
    onUpdateReservation(shopId: $shopId) {
      shopId
      id
      status
      expiredAt
      isExpired
      scheduleId
      schedule {
        shopId
        id
        eventId
        event {
          id
          shopId
          name
          thumbnail
          description
          duration
          capacity
          canPublish
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        capacity
        acceptableNumber
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        isPublished
        isExpired
        createdAt
        updatedAt
      }
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        name
        lineId
        email
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteReservation = /* GraphQL */ `
  subscription OnDeleteReservation($shopId: String) {
    onDeleteReservation(shopId: $shopId) {
      shopId
      id
      status
      expiredAt
      isExpired
      scheduleId
      schedule {
        shopId
        id
        eventId
        event {
          id
          shopId
          name
          thumbnail
          description
          duration
          capacity
          canPublish
          color
          order
          cancelTimeoutLimit
          reserveTimeoutLimit
          createdAt
          updatedAt
        }
        start
        end
        capacity
        acceptableNumber
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        isPublished
        isExpired
        createdAt
        updatedAt
      }
      transactionId
      transaction {
        reservationId
        id
        customerId
        details {
          id
          process
          status
          message
          createdAt
        }
        createdAt
        updatedAt
      }
      customerId
      customer {
        shopId
        id
        name
        lineId
        email
        reservations {
          items {
            shopId
            id
            status
            expiredAt
            isExpired
            scheduleId
            transactionId
            customerId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateReservationTransaction = /* GraphQL */ `
  subscription OnCreateReservationTransaction {
    onCreateReservationTransaction {
      reservationId
      id
      customerId
      details {
        id
        process
        status
        message
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateReservationTransaction = /* GraphQL */ `
  subscription OnUpdateReservationTransaction {
    onUpdateReservationTransaction {
      reservationId
      id
      customerId
      details {
        id
        process
        status
        message
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteReservationTransaction = /* GraphQL */ `
  subscription OnDeleteReservationTransaction {
    onDeleteReservationTransaction {
      reservationId
      id
      customerId
      details {
        id
        process
        status
        message
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer {
    onCreateCustomer {
      shopId
      id
      name
      lineId
      email
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          schedule {
            shopId
            id
            eventId
            start
            end
            capacity
            acceptableNumber
            isPublished
            isExpired
            createdAt
            updatedAt
          }
          transactionId
          transaction {
            reservationId
            id
            customerId
            createdAt
            updatedAt
          }
          customerId
          customer {
            shopId
            id
            name
            lineId
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer {
    onUpdateCustomer {
      shopId
      id
      name
      lineId
      email
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          schedule {
            shopId
            id
            eventId
            start
            end
            capacity
            acceptableNumber
            isPublished
            isExpired
            createdAt
            updatedAt
          }
          transactionId
          transaction {
            reservationId
            id
            customerId
            createdAt
            updatedAt
          }
          customerId
          customer {
            shopId
            id
            name
            lineId
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer {
    onDeleteCustomer {
      shopId
      id
      name
      lineId
      email
      reservations {
        items {
          shopId
          id
          status
          expiredAt
          isExpired
          scheduleId
          schedule {
            shopId
            id
            eventId
            start
            end
            capacity
            acceptableNumber
            isPublished
            isExpired
            createdAt
            updatedAt
          }
          transactionId
          transaction {
            reservationId
            id
            customerId
            createdAt
            updatedAt
          }
          customerId
          customer {
            shopId
            id
            name
            lineId
            email
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
