import { API } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { ListSchedulesQuery, Schedule } from "../../API";
import { listSchedules } from "../../graphql/queries";

export const ScheduleRepository = (shopId: string, lineIdToken: string) => {
  const fetch = () => {
    return new Promise<Schedule[]>(async (res, rej) => {
      if (shopId === undefined) rej();
      const result = (await API.graphql({
        query: listSchedules,
        variables: { shopId: shopId },
        authMode: "AWS_LAMBDA",
        authToken: lineIdToken,
      })) as GraphQLResult<ListSchedulesQuery>;

      if (result.data?.listSchedules?.items) {
        const schedules = result.data.listSchedules.items as Schedule[];
        res(schedules);
      } else {
        rej(new Error("empty"));
      }
    });
  };
  return { fetch };
};
