import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CustomerIndexPage } from "../pages/mypage/CustomerIndexPage";
import TopPageContextProvider from "../provider/TopPageContextProvider";
import { ReservePage } from "../pages/ReservePage";
import { SchedulePage } from "../pages/SchedulePage";
import { TopPage } from "../pages/TopPage";
import { LiffReservationClient } from "../theme/LiffReservationClient";
import { LiffReservationMyPage } from "../theme/LiffReservationMyPage";
import { CustomerReservationsPage } from "../pages/mypage/CustomerReservationsPage";
import { ProtectedRoute } from "./ProtectedRoute";
import { ReservationTransactionPage } from "../pages/ReservationTransactionPage";
import { PublicRoute } from "./PublicRoute";

export const AppRouter = () => {
  const prefix = "/s";
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LiffReservationClient />}>
          <Route element={<ProtectedRoute />}>
            <Route
              path={prefix + "/:shopId"}
              element={
                <TopPageContextProvider>
                  <TopPage />
                </TopPageContextProvider>
              }
            />
            <Route
              path={prefix + "/:shopId/sc/:scheduleId"}
              element={<SchedulePage />}
            />
            <Route
              path={prefix + "/:shopId/sc/:scheduleId/reserve"}
              element={<ReservePage />}
            />
            <Route
              path={prefix + "/:shopId/sc/:scheduleId/reserve/transaction"}
              element={<ReservationTransactionPage />}
            />
            {/* mypage */}
            <Route element={<LiffReservationMyPage />}>
              <Route
                path={prefix + "/:shopId/c/:customerId"}
                element={<CustomerIndexPage />}
              />
              <Route
                path={prefix + "/:shopId/c/:customerId/reservations"}
                element={<CustomerReservationsPage />}
              />
            </Route>
          </Route>
          <Route element={<PublicRoute />}>
            <Route
              path={"/"}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
