import { useEffect } from "react";
import { Navigate, Outlet, RouteProps, useParams } from "react-router-dom";
import { useApplicationStore } from "../provider/ApplicationStoreProvider";
import { useNavigate,useSearchParams } from "react-router-dom";
export const PublicRoute: React.FC<RouteProps> = () => {
  const { shopId } = useParams();
  const [search, setSearch] = useSearchParams();
  const redirect_path = `/s/${shopId}/`;
  const { store } = useApplicationStore();
  const navigate = useNavigate();
  useEffect(() => {
    const sid = search.get("sid"); 
    if(sid!==null){
      navigate(`/s/${sid}/`)
    }
  }, []);
  return store.customer === null ? <Outlet /> : <Navigate to={redirect_path} />;
};
