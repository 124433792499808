import { Typography, Card, Stack, Box, Chip, CardProps } from "@mui/material";
import React from "react";
import { Schedule } from "../../../API";

type Props = CardProps & {
  schedule: Schedule;
};

export const ScheduleCard = React.memo<Props>(
  ({ schedule, children, ...props }) => {
   
    return (
      <Card sx={{ width: "100%" }} variant="outlined" {...props}>
        <Stack spacing={2} justifyContent="center" px={1.75} pt={2} pb={1.5}>
          <Box>
            <Stack>
              <Typography variant="h3">{schedule.event?.name}</Typography>
            </Stack>
          </Box>
          {children}
        </Stack>
      </Card>
    );
  }
);
